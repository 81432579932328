import { Injectable } from "@angular/core";
import { Address } from "../../models/address";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class CbMapDataService {
	private _personnalAddress: BehaviorSubject<Address | null> = new BehaviorSubject<Address | null>(null);
	public personnalAddress: Observable<Address | null> = this._personnalAddress.asObservable();

	private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isLoading: Observable<boolean> = this._isLoading.asObservable();

	private _relayAddresses: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>([]);
	public relayAddresses: Observable<Address[]> = this._relayAddresses.asObservable();

	private _choosenRelayAddress: BehaviorSubject<Address | null> = new BehaviorSubject<Address | null>(null);
	public choosenRelayAddress: Observable<Address | null> = this._choosenRelayAddress.asObservable();

	constructor() {}

	public getPersonnalAddress = (): Address | null => this._personnalAddress.getValue();
	public getIsLoading = (): boolean => this._isLoading.getValue();
	public getRelayAddresses = (): Address[] => this._relayAddresses.getValue();
	public getChoosenRelayAddress = (): Address | null => this._choosenRelayAddress.getValue();

	public setPersonnalAddress = (address: Address): void => this._personnalAddress.next(address);
	public setIsLoading = (status: boolean): void => this._isLoading.next(status);
	public setRelayAddresses = (addresses: Address[]): void => this._relayAddresses.next(addresses);
	public setChoosenRelayAddress = (address: Address): void => this._choosenRelayAddress.next(address);
}
