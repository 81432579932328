import { HttpHeaders } from "@angular/common/http";

import { API_TOKEN, APP } from "../../constants";

export const HEADERS: HttpHeaders = new HttpHeaders({

  "Content-Type": "application/json; charset=utf-8",
  "api_key": API_TOKEN,
  "app_uuid": APP.UUID,
  "app_name": APP.NAME,
  "app_version": APP.VERSION

});